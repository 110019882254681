<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Lista de Precios - Productos</h4>
            <div class="small text-muted">Cargar de productos a la lista de precios.</div>
          </b-col>           
        </b-row>
      </b-card>
      
      <b-row>
        <b-col>
          
          <b-card title="Productos sin agregar" 
                  header-tag="header" 
                  footer-tag="footer">      
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda para filtrar la grilla" v-model="tableSub.filter"></b-form-input>              
                  </b-input-group>
                </b-form-group>                
              </b-col>

              <b-col md="12">
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"                    
                        v-if="table.items.length">                       

                    <template v-slot:cell(id)="data">
                      <strong>{{data.item.id}}</strong>
                    </template>

                    <template v-slot:cell(name)="data">
                      <b>{{data.item.name}}</b>
                      <b-icon icon="circle-fill" v-if="!data.item.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Producto INACTIVO" />                           
                      <div v-if="hasLines">
                        <div v-if="data.item.subline">
                          <b-icon icon="chevron-double-right" ></b-icon> {{data.item.subline.line.name}} - {{data.item.subline.name}}<br>
                        </div>
                      </div>
                      <div>
                        <b-icon icon="chevron-double-right"></b-icon> {{data.item.subcategory.category.name}} - {{data.item.subcategory.name}}
                      </div>                       
                    </template>

                    <template v-slot:cell(observations)="data">
                      <div class="price-list-products-table-observations" 
                           v-if="(hasColors && data.item.colors.length) || 
                                 (hasWaist && data.item.waist.length) ||
                                 (hasColorsWaist && data.item.colors_waist.length)">
                                 
                        <div v-if="hasColors && data.item.colors.length">
                          <b>({{data.item.colors.length}}) COLOR/ES</b>
                        </div>
                      
                        <div v-if="hasWaist && data.item.waist.length">
                          <b>({{data.item.waist.length}}) TALLE/S</b>
                        </div>                         
                        <div v-if="hasColorsWaist && data.item.colors_waist.length">
                          <b>({{data.item.colors_waist.length}}) COLOR/ES + TALLE/S</b>
                        </div> 
                      </div>
                      <div v-else class="text-warning">
                        <b>Sin variedades</b>
                      </div>
                    </template>

                    <template v-slot:cell(f_action)="data">                      
                        <b-button size="sm" 
                                  @click="add(data.item)" 
                                  variant="outline-dark" 
                                  title="Agregar Producto"
                                  class="pull-right">
                          <b-icon icon="arrow-right-short"></b-icon>
                        </b-button>                                                                      
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(table.items)"
                                :per-page="table.perPage"
                                v-model="table.currentPage" />
                </nav>
              </b-col>                    
            </b-row>
          </b-card>
          
        </b-col>

        <b-col v-if="list">          
          <b-card :title="'Lista ID:' + this.listID + ' - ' + this.list.name + ' - ' + this.list.currency.code" 
                  header-tag="header" 
                  footer-tag="footer">      

            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda para filtrar la grilla" v-model="tableSub.filter"></b-form-input>              
                  </b-input-group>
                </b-form-group>                
              </b-col>
              <b-col md="12">
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="tableSub.items"
                        :fields="tableSub.fields"
                        :filter="tableSub.filter"
                        :current-page="tableSub.currentPage"
                        :per-page="tableSub.perPage"                    
                        v-if="tableSub.items.length">      

                    <template v-slot:cell(id)="data">
                      <div v-if="data.item.products">
                        <strong>{{data.item.products.id}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(amount)="data">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.list.currency.code }).format(data.item.amount_initial)}}                      
                      <span v-if="data.item.unit_measurement_initial">{{data.item.unit_measurement_initial.reference}}</span>
                    </template>

                    <template v-slot:cell(name)="data">
                      <div v-if="data.item.products">
                        <b>{{data.item.products.name}}</b>
                        <b-icon icon="circle-fill" v-if="!data.item.products.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Producto INACTIVO" />                           
                        <div v-if="hasLines">
                          <div v-if="data.item.products.subline">
                            <b-icon icon="chevron-double-right" ></b-icon> {{data.item.products.subline.line.name}} - {{data.item.products.subline.name}}<br>
                          </div>
                        </div>
                        <div v-if="data.item.products.subcategory">
                          <b-icon icon="chevron-double-right"></b-icon> {{data.item.products.subcategory.category.name}} - {{data.item.products.subcategory.name}}
                        </div>                        
                      </div>
                    </template>

                    <template v-slot:cell(observations)="data">   
                      
                      <div v-if="data.item.products">        
                        <b-link :id="'popover' + data.index"   
                                @click="edit(data.item)"                         
                                class="price-list-products-table-observations"                           
                                v-if="(hasColors && data.item.products.colors.length) || 
                                      (hasWaist && data.item.products.waist.length) || 
                                      (hasColorsWaist && data.item.products.colors_waist.length)">                                             
                          
                          <div v-if="hasColors && data.item.products.colors.length">
                            <b>({{data.item.products.colors.length}}) COLOR/ES</b>
                          </div>
                        
                          <div v-if="hasWaist && data.item.products.waist.length">
                            <b>({{data.item.products.waist.length}}) TALLE/S</b>
                          </div>  

                          <div v-if="hasColorsWaist && data.item.products.colors_waist.length">
                            <b>({{data.item.products.colors_waist.length}}) COLOR/ES + TALLE/S</b>
                          </div> 
                        </b-link>
                        <b-link :id="'popover' + data.index"   
                                @click="edit(data.item)"                         
                                class="text-warning"
                                v-else>
                          <b>Sin variedades</b>
                        </b-link>

                        <b-popover triggers="hover" 
                                  variant="default" 
                                  :target="'popover' + data.index" 
                                  @show="getVariedades(data.item)">
                          
                          <template v-slot:title>
                            <b>Variedades</b>
                          </template>                        
                          <div v-html="popover"></div>
                        </b-popover>
                      </div>
                    </template>
                  
                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                                                                         
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(tableSub.items)"
                                :per-page="tableSub.perPage"
                                v-model="tableSub.currentPage" />
                </nav>
              </b-col>                    
            </b-row>
          </b-card>          
        </b-col>        
      </b-row>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>         
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->      
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row v-if="crud.form.products">
          <b-col md="12">
            <table class="table b-table table-sm table-hover b-table-caption-top">
              <thead class="thead-dark">       
                <tr>
                  <th colspan="4" class="text-center">PRODUCTO</th>
                </tr>
                <tr>
                  <th class="w-33">Código</th>
                  <th class="w-33">Nombre</th>                  
                  <th class="w-33">Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{this.crud.form.products.code}}</td>
                  <td>{{this.crud.form.products.name}}</td>
                  <td>
                    <b-form-input type="number"                                    
                                  size="sm"    
                                  autofocus                              
                                  @focus="$event.target.select()"
                                  v-model="crud.form.amount_total">
                    </b-form-input>      
                  </td>
                </tr>
              </tbody>    
            </table>            
          </b-col>
          
          <b-col md="12" v-if="crud.form.products.colors.length>0">

            <table class="table b-table table-sm table-hover b-table-caption-top">
              <thead class="thead-light">       
                <tr>
                  <th colspan="4" class="text-center">COLOR/ES</th>
                </tr>
                <tr>
                  <th class="w-25">Código</th>
                  <th class="w-25">Nombre</th>                  
                  <th class="w-25">Variedad</th>
                  <th class="w-25">Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="value in crud.form.products.colors" v-bind:key="value.id">
                  <td>{{value.code}}</td>
                  <td>{{value.name}}</td>
                  <td v-if="value.color">{{value.color.name}}</td>
                  <td>
                    <b-form-input type="number"                                    
                                  size="sm"                                  
                                  @focus="$event.target.select()"
                                  v-model="crud.form.colorsTmp[value.id]">
                    </b-form-input>      
                  </td>
                </tr>
              </tbody>    
            </table>                           
          </b-col>
                    
          <b-col md="12" v-if="crud.form.products.waist.length>0">
            <table class="table b-table table-sm table-hover b-table-caption-top">
              <thead class="thead-light">       
                <tr>
                  <th colspan="4" class="text-center">TALLE/S</th>
                </tr>
                <tr>
                  <th class="w-25">Código</th>
                  <th class="w-25">Nombre</th>                  
                  <th class="w-25">Variedad</th>
                  <th class="w-25">Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="value in crud.form.products.waist" v-bind:key="value.id">
                  <td>{{value.code}}</td>
                  <td>{{value.name}}</td>
                  <td v-if="value.waist">{{value.waist.name}}</td>                  
                  <td>
                    <b-form-input type="number"                                    
                                  size="sm"                                  
                                  @focus="$event.target.select()"
                                  v-model="crud.form.waistTmp[value.id]">
                    </b-form-input>    
                  </td>
                </tr>
              </tbody>    
            </table>                             
          </b-col>   
          
          <b-col md="12" v-if="crud.form.products.colors_waist.length>0">
            <table class="table b-table table-sm table-hover b-table-caption-top">
              <thead class="thead-light">       
                <tr>
                  <th colspan="4" class="text-center">COLOR/ES + TALLE/S</th>
                </tr>
                <tr>
                  <th class="w-25">Código</th>
                  <th class="w-25">Nombre</th>                  
                  <th class="w-25">Variedad</th>
                  <th class="w-25">Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="value in crud.form.products.colors_waist" v-bind:key="value.id">
                  <td>{{value.code}}</td>
                  <td>{{value.name}}</td>                  
                  <td v-if="value.color && value.waist">{{value.color.name}} - {{value.waist.name}}</td>                  
                  <td>
                    <b-form-input type="number"                                    
                                  size="sm"                                  
                                  @focus="$event.target.select()"
                                  v-model="crud.form.colorsWaistTmp[value.id]">
                    </b-form-input>    
                  </td>
                </tr>
              </tbody>    
            </table>                             
          </b-col>   
          
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import Param from '@/config/parameters'

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.PRECIOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPricesListsProducts',
          elements: {}
        }, 
        parameters: {
          haveLines: Helper.hasParametersAccess(Param.P7),
          haveColors: Helper.hasParametersAccess(Param.P8),
          haveWaist: Helper.hasParametersAccess(Param.P9),
          haveMeasurement: Helper.hasParametersAccess(Param.P10),
          haveColorsWaist: Helper.hasParametersAccess(Param.P17),
        },         
        listID: 0,
        list: null,
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle'},
            {key: 'name', label: 'Nombre', class: 'align-middle'},                                
            {key: 'observations', label: 'Observaciones', class: 'align-middle'},            
            {key: 'f_action', label:'', class: 'align-middle'},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 10,
        },
        tableSub : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle'},
            {key: 'name', label: 'Nombre', class: 'align-middle'},            
            {key: 'amount', label: 'Precio', class: 'align-middle'},          
            {key: 'observations', label: 'Observaciones', class: 'align-middle'},            
            {key: 'f_action', label:'', class: 'align-middle'},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 10,
        },                
        crud: {
          form: {
            id: 0,
            price_list_id: 0,
            products_id: 0,  
            products: null, 
            waist: [],
            waistTmp: [],
            colors: [],  
            colorsTmp: [],  
            colors_waist: [],  
            colorsWaistTmp: [],
            amount:0,                      
            amount_total:0,
          },           
        },
        modal: {
          form: {
            active: false,
            title: ''
          },          
        },
        popover: ''                           
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.listID = this.$route.params.listID  
      this.loadList()

      this.load()
      this.loadSub()
    },
    computed: {
      hasLines() {
        return this.parameters.haveLines
      },
      hasWaist() {
        return this.parameters.haveWaist
      },
      hasColors() {
        return this.parameters.haveColors
      },
      hasColorsWaist() {
        return this.parameters.haveColorsWaist
      },      
      hasAnyVariant() {      
        return this.hasColors || this.hasWaist || this.hasColorsWaist
      },
      hasAllVariant() {      
        return this.hasColors && this.hasWaist && this.hasColorsWaist
      },      
      hasMeasurement() {        
        return this.parameters.haveMeasurement
      }      
    },    
    methods: {
      getRowCount (items) {
        return items.length
      },
      loadList() {        
        var result = serviceAPI.mostrarLista(this.listID)

        result.then((response) => {
          var data = response.data                         
          this.list = data
        })
      },
      load() {        
        var result = serviceAPI.obtenerProducto({
          price_list_id: this.listID
        })

        result.then((response) => {
          var data = response.data
          this.table.items = data                  
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });    
      },
      loadSub() {        
        var result = serviceAPI.obtenerListaProductosByLista({
          price_list_id: this.listID
        })

        result.then((response) => {
          var data = response.data          
          this.tableSub.items = data    
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });    
      },

      add(item) {        
        this.crud.form.id = 0
        this.crud.form.price_list_id = this.listID
        this.crud.form.products_id = item.id
        this.crud.form.products = item
        this.crud.form.amount = 0
        this.crud.form.amount_total = 0         
        this.crud.form.colors = []
        this.crud.form.colorsTmp = []
        this.crud.form.waist = []
        this.crud.form.waistTmp = []
        this.crud.form.colors_waist = []
        this.crud.form.colorsWaistTmp = []

        if(item.colors) {
          item.colors.forEach(element1 => {            
            this.crud.form.colorsTmp[element1.id] = 0
          });
        }
        
        if(item.waist) {
          item.waist.forEach(element2 => {            
            this.crud.form.waistTmp[element2.id] = 0
          });
        }
                
        if(item.colors_waist) {
          item.colors_waist.forEach(element3 => {            
            this.crud.form.colorsWaistTmp[element3.id] = 0
          });
        }
        
        this.modal.form.title = "Agregar Producto"
        this.modal.form.active = true
      },
      edit(item) {
        var result = serviceAPI.mostrarProductosByLista({
          price_list_id: this.listID,
          products_id: item.products_id
        })

        result.then((response) => {
          var data = response.data
          
          this.crud.form.id = data.products.id
          this.crud.form.price_list_id = data.products.price_list_id
          this.crud.form.products_id = data.products.products_id
          this.crud.form.products = data.products.products
          this.crud.form.products.colors = data.colors
          this.crud.form.products.waist = data.waist
          this.crud.form.products.colors_waist = data.colors_waist
          this.crud.form.amount = data.products.amount_initial
          this.crud.form.amount_total = data.products.amount_initial          

          if(data.colors) {
            data.colors.forEach(element1 => {            
              this.crud.form.colorsTmp[element1.id] = element1.amount_total
            });
          }
          
          if(data.waist) {
            data.waist.forEach(element2 => {            
              this.crud.form.waistTmp[element2.id] = element2.amount_total
            });
          }
          
          if(data.colors_waist) {
            data.colors_waist.forEach(element3 => {            
              this.crud.form.colorsWaistTmp[element3.id] = element3.amount_total
            });
          }

          this.modal.form.title = "Editar Producto"
          this.modal.form.active = true

        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });         
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.products.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el producto '+ this.crud.form.name + ' de la lista ' + this.list.name + '?', {
          title: 'Quitar Producto de la Lista',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarListaProductos(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.loadSub()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {               
        let loader = this.$loading.show();      
        
        if(this.crud.form.products.colors) {
          this.crud.form.products.colors.forEach(element1 => {
            this.crud.form.colors.push({
              id: element1.id,
              amount: this.crud.form.colorsTmp[element1.id]
            })            
          });
        }

        if(this.crud.form.products.waist) {
          this.crud.form.products.waist.forEach(element2 => {
            this.crud.form.waist.push({
              id: element2.id,
              amount: this.crud.form.waistTmp[element2.id]
            })            
          });
        }        

        if(this.crud.form.products.colors_waist) {
          this.crud.form.products.colors_waist.forEach(element3 => {
            this.crud.form.colors_waist.push({
              id: element3.id,
              amount: this.crud.form.colorsWaistTmp[element3.id]
            })            
          });
        }  

        if (this.crud.form.id) {
          var result = serviceAPI.editarListaProductos(this.crud.form);
        } else {
          var result = serviceAPI.agregarListaProductos(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.loadSub()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },  
      
      getVariedades(item) {                
        var result = serviceAPI.mostrarProductosByLista({
          price_list_id: this.listID,
          products_id: item.products_id
        })
        
        result.then((response) => {          
          var data = response.data
          var colors = ''
          var waist = ''
          var colorWaist = ''          

          if(data.colors.length>0) {            
            colors = colors + '<table class="table b-table table-sm table-hover b-table-caption-top">'           
            colors = colors + '<thead class="thead-dark">'           
            colors = colors + '<tr><th colspan="4" class="text-center">COLOR</th></tr>'     
            colors = colors + '<tr><th>Código</th><th>Nombre</th><th>Variedad</th><th>Precio</th></tr>'
            colors = colors + '</thead><tbody>'           
            data.colors.forEach(element1 => {   
              var code = ''
              var amount = 0
              var name = ''
              var variedad = ''

              if(element1.code) {
                code = element1.code
              }
              if(element1.name) {
                name = element1.name
              }
              if(element1.color) {
                variedad = element1.color.name
              }              
              if(element1.amount_total) {
                amount = Intl.NumberFormat('es-AR',{style:'currency',currency: item.list.currency.code }).format(element1.amount_total)
              }                            

              colors = colors + "<tr>"
              colors = colors + "<td>" + code + "</td>"
              colors = colors + "<td>" + name + "</td>"
              colors = colors + "<td>" + variedad + "</td>"
              colors = colors + "<td>" + amount + "</td>"              
              colors = colors + "</tr>"
            })
            colors = colors + "</tbody></tables>"
          }          
          
          if(data.waist.length>0) {
            waist = waist + '<table class="table b-table table-sm table-hover b-table-caption-top">'    
            waist = waist + '<thead class="thead-dark">'    
            waist = waist + '<tr><th colspan="4" class="text-center">TALLE</th></tr>'       
            waist = waist + '<tr><th>Código</th><th>Nombre</th><th>Variedad</th><th>Precio</th></tr>'
            waist = waist + '</thead><tbody>'    
            data.waist.forEach(element2 => {                     
              var code = ''
              var amount = 0
              var name = ''
              var variedad = ''

              if(element2.code) {
                code = element2.code
              }
              if(element2.name) {
                name = element2.name
              }
              if(element2.waist) {
                variedad = element2.waist.name
              }                  
              if(element2.amount_total) {
                amount = Intl.NumberFormat('es-AR',{style:'currency',currency: item.list.currency.code }).format(element2.amount_total)
              }                            
              
              waist = waist + "<tr>"
              waist = waist + "<td>" + code + "</td>"
              waist = waist + "<td>" + name + "</td>"
              waist = waist + "<td>" + variedad + "</td>"
              waist = waist + "<td>" + amount + "</td>"              
              waist = waist + "</tr>"
            });
            waist = waist + "</tbody></tables>"
          }          
          
          if(data.colors_waist.length>0) {
            colorWaist = colorWaist + '<table class="table b-table table-sm table-hover b-table-caption-top">'    
            colorWaist = colorWaist + '<thead class="thead-dark">'    
            colorWaist = colorWaist + '<tr><th colspan="4" class="text-center">COLOR + TALLE</th></tr>'       
            colorWaist = colorWaist + '<tr><th>Código</th><th>Nombre</th><th>Variedad</th><th>Precio</th></tr>'
            colorWaist = colorWaist + '</thead><tbody>'    
            data.colors_waist.forEach(element3 => {                     
              var code = ''
              var amount = 0
              var name = ''
              var variedad = ''

              if(element3.code) {
                code = element3.code
              }
              if(element3.name) {
                name = element3.name
              }
              if(element3.color && element3.waist) {
                variedad = element3.waist.name + ' - ' + element3.color.name
              }                        
              if(element3.amount_total) {
                amount = Intl.NumberFormat('es-AR',{style:'currency',currency: item.list.currency.code }).format(element3.amount_total)
              }                            
              
              colorWaist = colorWaist + "<tr>"
              colorWaist = colorWaist + "<td>" + code + "</td>"
              colorWaist = colorWaist + "<td>" + name + "</td>"
              colorWaist = colorWaist + "<td>" + variedad + "</td>"
              colorWaist = colorWaist + "<td>" + amount + "</td>"              
              colorWaist = colorWaist + "</tr>"
            });
            colorWaist = colorWaist + "</tbody></tables>"
          }          

          if(waist || colors || colorWaist) {
            this.popover = colors + waist + colorWaist
          } else {
            this.popover = "Sin Variedad"
          }

        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });           
      }
    }    
  }
</script>
<style>
  .price-list-products-table-observations {
    color: darkblue;
  }
</style>